import {
  commentGetLike,
  findContent,
  findMyContent,
  findProducts,
  getContentWithLike,
  likeSend,
} from "./content";

// import logoBeee from '../../../assets/logoBeee.png';
import {
  userDeleteBlocker,
  userGetListBlocker,
  userPostBlocker,
} from "./compte";
import { toast } from "react-toastify";
import { attributesAll } from "../../../res/constants";

export const getPageContent = ({
  p_numberPage,
  p_numberPerPage,
  p_visibilities,
  p_pseudo,
  p_category,
  p_id,
  p_tags,
  p_status,
}) => {
  const data = {
    "order.ascending": "false",
    visibilities: `${p_visibilities}`,
  };
  if (p_tags) {
    data["tags"] = p_tags;
  }
  if (p_numberPage) {
    data["pagination.page"] = p_numberPage;
  }
  if (p_numberPerPage) {
    data["pagination.par_page"] = p_numberPerPage;
  }
  if (p_pseudo) {
    data["pseudo"] = p_pseudo;
  }
  if (p_id) {
    data["user_id"] = p_id;
  }
  if (p_category) {
    data["category"] = p_category;
  }
  if (p_status) {
    data["status"] = p_status;
  }
  return new Promise((resolve, reject) => {
    findContent(data).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        console.log("ERREUR DE VALIDATION PAR EMAIL ", error);
        reject(error);
      }
    );
  });
};

export const getMyContent = ({ p_status, p_numberPage, p_numberPerPage }) => {
  const data = {
    "order.ascending": "false",
    // 'pagination.page': '1',
    // 'pagination.par_page': '10',
    // 'status': ['ONLINE', 'ARCHIVED', 'CREATED', 'SUSPENDED']
  };

  if (p_numberPage) {
    data["pagination.page"] = p_numberPage;
  }
  if (p_numberPerPage) {
    data["pagination.par_page"] = p_numberPerPage;
  }
  if (p_status) {
    data["status"] = p_status;
  }

  return new Promise((resolve, reject) => {
    findMyContent(data).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
};

export const getProductsAll = ({
  p_numberPage,
  p_numberPerPage,
  p_visibilities,
  p_pseudo,
  p_category,
  p_id,
  p_tags,
}) => {
  const data = {
    "order.ascending": "false",
    visibilities: `${p_visibilities}`,
  };
  if (p_tags) {
    data["tags"] = p_tags;
  }
  if (p_numberPage) {
    data["pagination.page"] = p_numberPage;
  }
  if (p_numberPerPage) {
    data["pagination.par_page"] = p_numberPerPage;
  }
  if (p_pseudo) {
    data["pseudo"] = p_pseudo;
  }
  if (p_id) {
    data["user_id"] = p_id;
  }
  if (p_category) {
    data["category"] = p_category;
  }
  return new Promise((resolve, reject) => {
    findProducts(data).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        console.log("ERREUR DE VALIDATION PAR EMAIL ", error);
        reject(error);
      }
    );
  });
};

export const contentLikeSend = (p_id, isLike) => {
  return new Promise((resolve, reject) => {
    likeSend({ id: p_id, isLike }).then(
      (response) => {
        if (isLike) {
          // toast.success(`Vous avez aimé le contenu`);
          // toast.success(``);
        } else {
          // toast.success(``);
          // toast.success(`Vous n'aimez plus le contenu`);
        }
        resolve(response);
      },
      (error) => {
        console.log("ERREUR LIKE", error);
        reject(error);
      }
    );
  });
};

export const commentGetLikes = (p_id, isLike) => {
  return new Promise((resolve, reject) => {
    commentGetLike(p_id, isLike).then(
      (response) => {
        if (isLike) {
          // toast.success(`Vous avez aimé le commentaire`);
        } else {
          // toast.success(`Vous n'aimez plus le commentaire`);
        }
        resolve(response);
      },
      (error) => {
        console.log("ERREUR LIKE", error);
        reject(error);
      }
    );
  });
};

export const contentGetWithLike = (p_id) => {
  return new Promise((resolve, reject) => {
    getContentWithLike(p_id).then(
      (response) => {
        resolve(response);
      },
      (error) => {
        console.log("ERREUR LIKE", error);
        reject(error);
      }
    );
  });
};

export const getListBloquer = () => {
  return new Promise((resolve, reject) => {
    userGetListBlocker().then(
      (response) => {
        resolve(response);
      },
      (error) => {
        console.log("ERREUR LIKE", error);
        reject(error);
      }
    );
  });
};

export const userBlocker = (id) => {
  return new Promise((resolve, reject) => {
    userPostBlocker(id).then(
      (response) => {
        resolve(response);
      },
      (error) => {
        console.log("ERREUR LIKE", error);
        reject(error);
      }
    );
  });
};

export const userDebloquer = (id) => {
  return new Promise((resolve, reject) => {
    userDeleteBlocker(id).then(
      (response) => {
        resolve(response);
      },
      (error) => {
        console.log("ERREUR LIKE", error);
        reject(error);
      }
    );
  });
};

export const getSizeObject = (obj) => {
  const objectLenght = Object.keys(obj).length;
  return objectLenght;
};

export const statusGenerator = ({ requestDetails, userId }) => {
  /*
    [
      "Followers",
      "Following",
      "Receiving",
      "Waiting",
      "Nothing",
    ]
  */
  const attributes = [];
  if (requestDetails !== null) {
    requestDetails.forEach((requestDetail) => {
      if (requestDetail.status === "VALIDATED") {
        if (requestDetail.sender === userId)
          //return attributesAll[0];
          attributes.push(attributesAll.following);
        if (requestDetail.user === userId)
          //return attributesAll[1];
          attributes.push(attributesAll.followers);
      }
      if (requestDetail.status === "CREATED") {
        if (requestDetail.sender === userId)
          //return attributesAll[2];
          attributes.push(attributesAll.receiving);
        if (requestDetail.user === userId)
          //return attributesAll[3];
          attributes.push(attributesAll.waiting);
      }
    });
  } else {
    // return attributesAll[4];
    attributes.push(attributesAll.nothing);
  }
  return attributes;
};
